@import 'react-table-v6/react-table.css';
@import './base/variables';
@import './base/variables';

.input1-wrap {
  display: flex;
  flex-direction: column;
}

.input-type {
  .input1-wrap {
    display: flex;
    // flex-direction: column;
  }
}

.modal-inputs {
  height: 2.875em !important;
}

.admin-wrap {
  min-height: 80vh;

  .admin-table .rt-table .rt-tbody {
    background-color: #ffffff;

    .form-control {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      color: $menu-color;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid $stroke;
      border-radius: 0.375rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      line-height: 1.5;
    }

    .rt-td {
      display: flex;
      color: $menu-color;
    }
  }

  .admin-table .rt-thead.-header {
    background-color: #ffffff;
    box-shadow: none;
    border-bottom: 1px solid $stroke;
    border-radius: 0px;

    .rt-th {
      display: flex;
    }
  }

  .action-column {
    display: flex;

    :first-child {
      margin-right: 5px;
      padding-left: 3px;
    }
  }

  .content-header {
    display: flex;
    align-items: center;
    margin: 25px 0px;
    padding-left: 10px;

    button {
      margin-left: 15px;
    }
  }

  .footer-btn {
    display: flex;
    align-items: center;
    float: right;
    margin: 25px 25px 0px 0px;
  }

  .modal-content {
    .modal-select {}

    .modal-input {
      height: 38px !important;
    }

    .css-1s2u09g-control {
      border-radius: 6px;
      border: 1px solid $input-gray;
    }

    .input1-wrap {
      display: flex;
      flex-direction: column;
    }
  }
}

.modal-search-select .css-1s2u09g-control,
.modal-search-select .css-1pahdxg-control {
  font-size: 0.9125em;
  height: $lg-height;
  border-color: $input-gray;
  width: 16em;
  border-radius: $md-radius;
  font-size: 0.9125em;

  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
}

.scrollable-modal {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollable-modal-dependent {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollable-modal-spec {
  max-height: 328px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.editor-wrap,
.description-wrap {
  // background-color: #ffffff;
  // max-height: 300px;
  // overflow-y: auto;
  // .ql-toolbar.ql-snow .ql-formats {
  //   margin-right: 0px;
  // }

  .ql-container {
    // border-bottom-left-radius: 0.5em;
    // border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }

  /* Snow Theme */
  .ql-snow.ql-toolbar {
    display: block;
    background: #fefcfc;
    // border-top-left-radius: 0.5em;
    // border-top-right-radius: 0.5em;
  }

  /* Bubble Theme */
  .ql-bubble .ql-editor {
    // border: 1px solid #ccc;
    border-radius: 0.5em;
  }

  .ql-editor {
    min-height: 18em;
    border: 1px solid #dde1e7 !important;
  }

  .themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
  }
}

.editor-wrap-description,
.description-wrap {
  // background-color: #ffffff;
  // max-height: 300px;
  // overflow-y: auto;
  // .ql-toolbar.ql-snow .ql-formats {
  //   margin-right: 0px;
  // }

  /* Snow Theme */
  .ql-snow.ql-toolbar {
    display: block;
    background: #fefcfc;
    pointer-events: none !important;
    // border-top-left-radius: 0.5em;
    // border-top-right-radius: 0.5em;
  }

  /* Bubble Theme */
  .ql-bubble .ql-editor {
    // border: 1px solid #ccc;
    border-radius: 0.5em;
  }

  .ql-editor {
    min-height: 18em;
  }

  .themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
  }
}

.description-wrap {
  max-height: 400px;
  overflow-y: auto;
}

.mt-110 {
  margin-top: 110px !important;
}

.table-pagination {
  .disabled {
    cursor: no-drop;
    opacity: 0.5;
  }
}

.Toastify__toast-container {
  z-index: 10000;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-break-all {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

.whitespace-pre {
  white-space: pre;
}

.disabled-field {
  cursor: no-drop;
  opacity: 0.5;
}

.color-light-blue {
  color: $brand-color;
}

.c-w-300 {
  width: 25.625em;
}

.c-w-full {
  width: 100%;
}

.custom-select {
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  color: #212529;
  background-size: 16px 12px;
  border: 1px solid #e8eff5;
  border-radius: 6px;
  word-wrap: normal;
}

.custom-select:focus-visible {
  border: 2px solid #e8eff5;
  outline: none;
}

.circle-ico-spec {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.card-inner-table {
  box-shadow: $shadow-light;
  background-color: $white;
  border: 1px solid $stroke;
  border-radius: $md-radius;
}

.spec-btn-wraper {
  margin-left: 10em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.spec-save-wraper {
  margin-right: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* Chrome, Safari, Edge, Opera */

.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* Firefox */

.hide-arrows {
  -moz-appearance: textfield;
}

.modal-sp {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.1em;
}

.c-pl-5 {
  padding-left: 6em;
}

.delete-card {
  top: 5px;
  right: 5px;
}

.editor-wrap {
  .ql-snow .ql-tooltip {
    top: 0 !important;
    right: 10px !important;
    left: 10px !important;
  }

  .ql-snow .ql-tooltip input[type='text'] {
    height: 26px !important;
    margin: 2px !important;
    width: 79% !important;
  }

  .disabled {
    pointer-events: none !important;
  }
}

.editor-wrap-description {
  .ql-snow .ql-tooltip {
    top: 0 !important;
    right: 10px !important;
    left: 10px !important;
  }

  .ql-snow .ql-tooltip input[type='text'] {
    height: 26px !important;
    margin: 2px !important;
    width: 79% !important;
  }

  .disabled {
    pointer-events: none !important;
  }
}

.modal-height {
  max-height: calc(100% - 45px) !important;
}

.c-ps-35 {
  padding-left: 35px;
}

.left-word-break {
  word-break: break-all;
  text-align: left;
}

.t-head {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}

.tag-count {
  font-size: x-small;
  background-color: $second-brand-color;
  color: white;
  border-radius: 35%;
  padding: 0px 5px;
  margin-left: 5px;
}

.project-top-10 {
  padding-top: 10em;
}

.project-top-12 {
  padding-top: 12em;
}

.project-top-8 {
  padding-top: 8.5em;
}

.tick-green {
  color: $green;
}

.leftmenu-space {
  margin-left: 8em;
}

.bg-blue {
  background-color: $body-gray !important;
}

.dot-dropdown {
  &.first_child {
    .dropdown-content {
      top: -12px;
      left: 22px;
      z-index: 9999 !important;
    }
  }

  .dropbtn {
    background: transparent;
    color: gray;
    padding: 4px;
    // transform: rotate(90deg);
    font-size: 12px;
    border: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: -28px;
    right: 20px;
    background-color: $white;
    min-width: 120px;
    box-shadow: $shadow-light;
    border-radius: $md-radius;
    border: 1px solid $stroke;
    min-height: 40px;
  }

  .dropdown-content a {
    color: $content-black;
    padding: 0px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    align-items: center;
  }

  .dropdown-content a:hover {
    background-color: $sky-blue;
    color: $brand-color;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background: transparent;
  }
}

.rct-sidebar-row {
  overflow: visible !important;
}

.align-text-justify {
  text-align: justify;
}

.align-text-end {
  text-align: end;
}

.align-text-left {
  text-align: left;
}

.align-text-center {
  text-align: center;
}

.react-calendar-timeline .rct-outer {
  overflow: visible !important;
}

.table-loader-wrap,
.spec-loader-wrap {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  top: 3em;
  bottom: 0;
  text-align: center;

  .table-loading {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    p {
      background-color: white;
      padding: 2em;
      margin-top: 1em;
      margin-bottom: 1em;
      font-weight: 500;
    }
  }
}

.spec-loader-wrap {
  position: relative !important;
}

.vessel-data-loader {
  top: 8em;
  z-index: 5;
}

.count-badge {
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.custom-table {
  width: 100%;

  .custom-table-header {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #2e3451;
    text-transform: uppercase;
    background: #ebf2ff;
    padding: 5px;
    margin: 0px;
  }

  .width-table-row-25 {
    width: 25%;
  }
}

.service-sub-table-border {
  border: 1px solid $stroke;
}

.service-sub-table {
  margin-left: 10em;
  margin-right: 1em;
  margin-bottom: 1em;
  border-radius: 8px;
}

.text-blue {
  color: $brand-color;
}

.text-yellow {
  color: $yellow;
}

.menudropdown {
  position: relative;
  display: inline-block;
}

.menudropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0px;
}

.menudropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.menudropdown-content a:hover {
  background-color: #f1f1f1;
}

.menudropdown:hover .menudropdown-content {
  display: block;
}

.notify-count {
  position: absolute;
  top: 0px;
  right: 8px;
  color: #ffffff;
  background-color: red;
  border-radius: 50%;
  padding: 0px 2px;
  min-width: 16px;
  font-size: 10px;
  font-weight: 600;
}

.visible-btn {
  position: absolute;
  top: 35px;
  right: 15px;
}

.login-visible-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}

.vessel-select {
  .css-1n7v3ny-option {
    background-color: #ebf2ff !important;
    padding: 0 !important;
  }

  .css-9gakcf-option {
    background-color: #ebf2ff !important;
    padding: 0 !important;
  }

  .css-1n7v3ny-option {
    :hover {
      background-color: #ebf2ff !important;
      cursor: pointer;
    }
  }

  .css-1wcka9y-control {
    background-color: $sky-white;
    border-radius: $lg-radius;
  }
}

// .error-field{
//   border-color: red;
// }
.comming-soon {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  min-height: calc(100vh - 250px);
}

.collapsed-tsi {
  margin-left: 9.5em;
  margin-right: 1em;
}

.html-table {
  margin-top: 5px;
  margin-bottom: 5em;

  // min-height: 200px;
  tr {
    // display: flex;
    // align-items: center;
    border-bottom: 1px solid $stroke;
    vertical-align: initial;

    &:hover {
      background-color: $sky-white;
    }

    th {
      // display: flex;
      // flex-direction: row;
      font-weight: 500;
      color: #091328;
    }

    th,
    td {
      padding: 8px 5px;
      text-align: left;
    }
  }
}

.attachment-read-only {
  .attachment-item:last-child {
    border-bottom: none;
  }
}

.spec-head {
  div {
    margin: -10px;
  }

  background-color: $sky-blue;
  height: 30px;
  padding: 0 20px 0 20px;
  margin: 12px 0 0 0;
  max-width: 500px;
}

.model-open {
  overflow: hidden;
}

.kfp-map {
  .rt-noData {
    margin-top: 35px !important;
  }
}

.quatity_popup {
  position: absolute;
  height: 95px;
  width: 220px;
  border: 1px solid #e8eff5;
  background-color: #ffffff;
  padding: 18px 12px 12px 12px;
  box-shadow: $shadow-light;
  z-index: 1;
  border-radius: 8px;
}

.secondary-btn-border {
  background: #fff;
  color: $brand-color;
  border: 1px solid $brand-color;
}

.blank-paint {
  margin: auto;
  width: 60em;
}

.paint-cost {
  margin-bottom: 6em;

  @supports (display: grid) {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto 1fr auto;

      //Let the craziness begin!!!
      grid-template-areas:
        'header header header'
        'title title footer'
        'main main main';

      @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.3fr 1fr auto 1fr;
        grid-template-areas:
          'header'
          'title'
          'main'
          'footer';
      }
    }

    .grid-item {
      padding: 3.5em 1em;
    }

    .main {
      grid-area: main;
      padding: 0;
      // overflow-x: hidden;
      // overflow-y: hidden;
    }

    .items {
      position: relative;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      transition: all 0.2s;
      user-select: none;
      cursor: pointer;
    }

    .items.active {
      cursor: grabbing;
      cursor: -webkit-grabbing;
    }

    .item {
      display: inline-block;
      // min-height: 400px;
      margin: 1em 1em 0 1em;
      min-width: 700px;
      vertical-align: top;
    }

    .item-collapsed {
      min-width: 360px !important;
    }

    .item-expanded {
      min-width: 400px;
    }

    .fixed-item {}
  }

  .hull-height {
    height: 4em;
    // margin-top: 1em;
  }

  .hull-height2 {
    height: 3em;
    margin-top: -2.86em;
  }

  .header-top {
    margin-top: 5.4em;
  }

  .header-top1 {
    margin-top: 1em;
  }

  .add-new-btn {
    padding-top: 0.9em;
    padding-bottom: 0.9em;
  }

  .no-data-paint {
    padding: 0.7em;
    color: white;
  }

  .region-div {
    display: flex;
    margin-left: 12em;
  }

  .region-div-colapsed {
    display: flex;
  }

  .region-div-total {
    display: flex;
    margin-left: -1px;
  }

  .region-div-inner {
    width: 10.9em;
    padding-right: 0.5em;
    // height: 3.8em;
    // padding-top: .2em;
    padding-left: 2em;
  }

  .p-w-10 {
    width: 10.9em;
  }

  .w-12em {
    width: 12.3em;
  }

  .region-divv {
    display: flex;
    margin-left: 12.5em;
  }

  .region-div-innerr {
    width: 12.3em;
    padding-right: 0.5em;
    height: 5em;
    padding-top: 1.5em;
    padding-left: 2em;
  }

  .region-div-innerr-cola {
    width: 12.3em;
    padding-right: 0.5em;
    height: 4em;
    padding-top: 1.5em;
    padding-left: 2em;
  }

  .region-div-innerr-collapsed {
    width: 11.3em;
    padding-right: 0.5em;
    height: 5em;
    padding-top: 1.5em;
    padding-left: 2em;
  }

  .region-div-inner-no-data {
    width: 12.3em;
    padding-right: 0.5em;
  }

  .firstvendor {
    margin-top: 5.3em;
  }

  .firstvendor-h {
    height: 5.3em;
    display: flex;
    margin-left: 12.5em;
  }

  .firstvendor-h-h {
    height: 5.3em;
    display: flex;
    margin-left: 12.25em;
  }

  .header-top-h {
    height: 5.4em;
    display: flex;
    margin-left: 12.5em;
  }

  .region-div-inner-h {
    width: 12.3em;
    padding-right: 0.5em;
    // height: 3.8em;
    // padding-top: .2em;
    padding-left: 2em;
  }

  .firstvendor-cola {
    margin-top: 5.3em;
  }

  .firstvendor-with {
    margin-top: 1.5em;
  }

  .paint-l-border {
    border-left: 1px solid #eee;
  }

  .paint-r-border {
    border-right: 1px solid #eee;
  }

  .pl-1 {
    padding-left: 1px;
  }

  .p-paint-2 {
    padding: 0.5rem 0.5rem 1.6em 0.5rem;
  }

  .h-total {
    height: 6em !important;
  }

  .paint-w-100 {
    width: 6em;
  }

  .v-p {
    padding: 15px 0 7px 20px;
  }

  .ml-venvor {
    margin-right: 2.6em;
  }

  .r-b {
    border-left: 1px solid #edf0ed;
    margin-left: 3px;
  }

  .transp {
    color: transparent;
  }

  .no-data-height {
    height: calc(100% - 25px) !important;
    text-align: center;

    div {}
  }

  .input-700 {
    input {
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.border-green {
  border: 1px solid $green;
}

.green-btn {
  font-size: 0.938em;
  border-radius: 6px;
  border: 0;
  background: $green;
  color: #fff;
  padding: 1em;
  font-weight: 500;
  height: 2.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.paint-min-item-height {
  min-height: 390px !important;
}

.text-black {
  color: #000;
}

.spec-arrow-width {
  width: 40px;
  height: 35px;
  font-weight: 500;
  color: #000;
  border-radius: 4px;
  text-align: center;
}

.spec-search {
  .icon-search {
    right: 8px !important;
  }

  .icon-close-line {
    top: 2px;
    right: 40px;
    border-radius: 6px;
    font-size: 12px;
    position: absolute;
    color: #75809e;
    cursor: pointer;
    width: 34px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: $brand-color;
    }
  }
}

.spec-search-content {
  position: absolute;
  background-color: #fff;
  max-width: 36.4em;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  left: auto;
  margin-left: 10px;
  padding-bottom: 0px;
  margin-top: 2px;
  max-height: 450px;
  overflow-y: scroll;
  border-radius: 6px;
}

.spec-search-list {
  cursor: pointer;
  border-radius: 6px;
}

.spec-search-list:hover {
  background-color: $hover-blue;
}

.spec-highlight {
  background-color: #fffac8;
  word-break: break-all;
}

.tsi-resource {
  margin-top: 7em;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding-right: 6px;
  min-height: 20em;
}

.react-tag__option {
  word-break: break-all !important;
}

.cursor-menu {
  cursor: context-menu;
}

.card-table-sec {
  padding: 10px;
}

.t-statusdot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #3fd963;
  margin-right: 4px;
}

.yardTable {
  .rt-tr {
    align-items: flex-start !important;
  }
}

.div_btn {
  background-color: $brand-color !important;
  padding: 4px 10px;
  border-radius: 4px;
  color: #ffffff;
}

.div_btn-gallery {
  background-color: $brand-color !important;
  padding: 9px 14px;
  border-radius: 4px;
  color: #ffffff;
}

.z-index-99 {
  z-index: 99;
}

.z-index-1 {
  z-index: 1;
}

.count-bar {
  background-color: $white;
  color: $brand-color;
  padding: 0 4px 0 4px;
  border-radius: 15px;
  margin-left: 5px;
  font-size: 12px;
}

.text-center {
  text-align: center;
}

.dependent-item {
  padding: 5px;
  padding-left: 1em;
}

.dependent-height {
  height: 58vh;
}

.text-box-dependent {
  border: 1px solid #e8eff5;
  height: 2.25em;
  border-radius: 6px;
}

.text-box-dependant {
  padding: unset !important;
}

.spec-item-dependant {
  padding-left: 1em !important;
}

.fixed-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 0;
  z-index: 7;
  color: $white;
  border-top: 1px solid $stroke;
  border-radius: 0;
  box-shadow: -1px -4px 10px rgb(0 0 0 / 3%);

  .dot-indicator {
    width: 10px;
    height: 10px;
    background-color: $red;
    border-radius: 50%;
    top: 5px;
    left: 210px;
    z-index: 2;
    position: absolute;
  }
}

.fixed-footer-close {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  color: $white;
  padding: 8px 0;
  border-top: 1px solid $stroke;
  border-radius: 0;
  box-shadow: -1px -4px 10px rgb(0 0 0 / 3%);

  .dot-indicator {
    width: 10px;
    height: 10px;
    background-color: $red;
    border-radius: 50%;
    top: 5px;
    left: 210px;
    z-index: 2;
    position: absolute;
  }
}

.mb-50 {
  margin-bottom: 60px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.with-close {
  padding: 0 5.5em 0 1em !important;
}

// .calender{
//   height: 500px;
//   width: 1000px
// }

.calender {
  height: 100% !important;
  width: 100% !important;
  // background: var(--clr-grey-600) !important;
  background: transparent !important;
  border-color: transparent !important;
  // color: var(--clr-grey-600);
}

.calendar-month-info {
  background-color: #ebf2ff;
  box-sizing: border-box;
  flex: 1;
}

.h-text-area {
  height: 100px;
}

.comments-btn {
  margin-left: 117px;
}

.supplementary-table {
  .supple-table-header {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #2e3451;
    text-transform: uppercase;
    background: #ebf2ff;
    padding: 5px;
    margin: 0px;
  }

  .service-sub-table {
    margin-left: 8em;
    margin-bottom: 1em;
    margin-right: 0em;
    border-radius: 8px;
  }
}

.pin-approval {
  position: absolute;
  top: 1.5em;
  left: 5em;
  color: white;
  z-index: 1;
  background-color: $orange;
  border-radius: 15px;
  padding: 0 0.5em 0 0.5em;
  font-size: 10px;
  animation: blink-animation 1s steps(8, start) infinite;
  -webkit-animation: blink-animation 1s steps(8, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.ra-border-10 {
  border-radius: 10px;
}

.ra-border-15 {
  border-radius: 15px;
}

.side-panel__content {
  .ql-container.ql-snow {
    border: none !important;
  }

  .editor-wrap-description .ql-editor,
  .description-wrap .ql-editor {
    min-height: 30em !important;
    border: 1px solid #dde1e7 !important;
  }
}

.v-thead-fixed {
  position: sticky;
  top: -1px;
}

.w-56 {
  width: 56em;
}

.w-52 {
  width: 52em;
}

.w-105 {
  width: 105px !important;
}

.quote_selcard {
  width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .head {
    height: 40px;
  }

  .details {
    color: gray;
    min-height: 160px;
  }
}

.quote_agreement {
  padding: 10px;
}

.description-main-panel {
  max-width: calc(100vh + 17em);
}

// .description-main{
//   max-width: calc(100vh + 7em);
// }
.terms-view {
  width: calc(98vw);
  height: calc(100vh - 305px);

  .text-editor,
  .editor-wrap-description,
  .quill,
  .ql-container {
    height: 100% !important;
  }
}

.content-tenderwrap {
  width: calc(100% - 135px);
  margin-left: 123px;
}

.text-right {
  text-align: right;
}

.text-content-grey {
  color: $menu-color;
}

.text-content-black {
  color: $content-black;
}

.view-jodit {

  .jodit-toolbar__box,
  .jodit-status-bar {
    display: none !important;
  }

  .yardterm-view {
    .jodit-wysiwyg {
      height: calc(100vh - 305px) !important;
    }
  }
}

.gimage_wrap {
  display: flex;
  flex-wrap: wrap;

  .gimage_view {
    width: 100px;
    height: 100px;
    border: 1px solid rgb(243, 242, 242);
    margin: 10px;
    position: relative;

    img {
      height: 98px;
    }

    span {
      position: absolute;
      background-color: $brand-color;
      color: #ffffff;
      border-radius: 50%;
      top: -14px;
      right: -14px;
      cursor: pointer;
    }
  }
}

.round-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $brand-color;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: sticky;
  bottom: 2px;
}

.avatar-sm {
  margin-left: 1px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.window-min-height {
  height: calc(100vh - 200px) !important;
}

.editDeleteSpec {
  position: absolute;
  left: 800px;
}

.input-icon {
  position: absolute;
  left: 15px;
  top: 35px;

  .email-wrap {
    .email-id {
      padding: 3px 0.5rem;
      border-radius: 100vmax;
      background-color: white;
      margin-left: 1rem;
      font-weight: 400;

      +.email-id {
        margin-left: 0.75rem;
      }
    }

    .email-subject {
      font-weight: 600;
      margin-left: 1rem;
    }

    .ql-editor {
      padding: 3rem 2.5rem;

      p {
        margin: 0;
        padding: 0;

        +p {
          margin-top: 0.25rem;
        }

        &:first-child {
          margin-bottom: 0;
        }
      }

      .ql-tooltip.ql-tooltip-editor {
        display: none;
      }
    }
  }

  .custom-jodit {
    width: 100%;

    .jodit-container {
      border-radius: 1rem !important;
      overflow: hidden;
    }

    .jodit-wysiwyg {
      height: 100%;
      min-height: 500px !important;
      background-color: #fff !important;
      padding-bottom: 1rem;
    }

    .jodit-workplace {
      height: 100%;
    }
  }

  .mr-edit-modal {
    margin-right: -7px;
  }

  .br-5 {
    border-radius: 5px;
  }

  .font-14-px {
    font-size: 14px;
  }
}

.spec-approve {
  background-color: #e3f6ff;
  border-left-width: 6px;
  border-left-style: solid;
  border-left-color: #007bff;
}

.spec-complete {
  background-color: #effef5;
  border-left-width: 6px;
  border-left-style: solid;
  border-left-color: #42bb7a;
}

.spec-partial {
  border-left-width: 6px;
  border-left-style: solid;
  border-left-color: #fbc04d;
  background-color: #fefdef;
}

.child-gallery {
  position: relative;

  span {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    border-radius: 8px;
    background-color: #fff;
  }

  .selected-image-dimension {
    width: 100px;
    height: 100px;
  }
}

.selected-parent-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.parent-gallery {
  display: flex;
  flex-wrap: wrap;
}

.img-thumbnail {
  margin: 8px;

  .image {
    // flex: 1 0 21%; /* explanation below */
    padding: 5px;
    background-color: #ffffff;
    position: relative;
    width: 7.25em;
    height: 7em;
    text-align: center;
    border-radius: 4px;

    &:hover {
      box-shadow: 0 0 10px #ccc;
    }

    img.image-dimension {
      height: 100%;
      max-height: 100%;
      width: auto;
      display: inline-block;
    }

    .name {
      font-size: 12px;
      font-weight: normal;
    }
  }

  .image-sub {
    padding: 5px;
    background-color: #ffffff;
    position: relative;
    width: 6.4em;
    height: 6.4em;
    text-align: center;
    border-radius: 4px;

    &:hover {
      box-shadow: 0 0 10px #ccc;
    }

    img.image-dimension {
      height: 100%;
      max-height: 100%;
      width: auto;
      display: inline-block;
    }

    .name {
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.h-12 {
  height: 12em !important;
}

.h-30 {
  height: 30em;
}

.rq-attachment {
  position: relative;

  .dot-indicator-rq {
    width: 10px;
    height: 10px;
    background-color: $red;
    border-radius: 50%;
    top: 4px;
    left: 113px;
    z-index: 2;
    position: absolute;
  }
}

.dot-indicator-variation {
  width: 10px;
  height: 10px;
  background-color: #d1414b;
  border-radius: 50%;
  top: 0px;
  right: 5px;
  z-index: 2;
  position: absolute;
}

.currentActual {
  color: $current-actual;
}

.deviation {
  color: $deviation;
}

.estimate {
  color: $estimate;
}

.estimated-color {
  color: #f5b1a5;
}

.actual-color {
  color: #3498db;
}

.bgcurrentActual {
  background-color: $current-actual;
}

.bgdeviation {
  background-color: $deviation;
}

.bgestimate {
  background-color: $estimate;
}

.pb-72 {
  padding-bottom: 72px;
}

.table-menudropdown-content {
  position: absolute;
  background-color: #000;
  color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 90px;
}

.font-40 {
  font-size: 3.6em;
}

.cost-popup {
  position: absolute;
  background: #444;
  color: #fff;
  width: 260px;
  max-height: 200px;
  top: 3em;
  font-size: 13px;
  overflow-y: auto;
}

.width-spec {
  width: 425px;
}

.scroll-wrap-dashboard {
  min-height: 30em !important;
}

.scroll-wrap-comments {
  max-height: 25em !important;
}

.small-input-box {
  width: 36px;
}

.repair-select__menu {
  z-index: 9 !important;
}

.jodit-full {
  .jodit-workplace {
    height: calc(100vh - 180px) !important;
  }

  .jodit-wysiwyg_mode {
    min-height: calc(100vh - 180px) !important;
  }
}

.jodit-temp-height {
  .jodit-workplace {
    height: calc(100vh - 270px) !important;
  }

  .jodit-container {
    height: calc(100vh - 217px) !important;
  }
}

.contact-img {
  width: 100px;
}

.photo-report {
  min-height: calc(100vh - 350px);

  .container {
    // max-width: 100rem;
    margin: 0 auto;
    // padding: 0 2rem 2rem;
    width: 840px;
  }

  .report-head {
    width: 790px;
    font-weight: 500;
    font-size: 2em;
    border: 2px dashed #ddd;
  }

  .heading span {
    display: block;
  }

  .gallery {
    // display: flex;
    // flex-wrap: wrap;
    /* Compensate for excess margin on outer gallery flex items */
    margin: -1rem -1rem;
  }

  .gallery-wraper {
    flex: 1 0 24rem;
    border: 2px dashed #ccc;

    &:hover {
      border: 2px dashed $brand-color;
    }
  }

  .gallery-item {
    /* Minimum width of 24rem and grow to fit available space */
    /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
    margin: 1rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 24em;
  }

  .gallery-image {
    display: block;
    width: 100%;
    height: 300px;
    object-fit: scale-down;
    // transition: transform 400ms ease-out;
    height: 100%;
    max-height: 100%;
  }

  .gallery-image:hover {
    // transform: scale(1.15);
  }

  @supports (display: grid) {
    .gallery {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(40rem, 0fr));
      grid-gap: 2rem;
    }

    .gallery,
    .gallery-item {
      margin: 0;
    }
  }

  .delete-button {
    position: absolute;
    right: 0;
    background-color: #fff;
    padding: 10px;

    span {
      color: red;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .photo-content {
    min-height: 34.5rem;
    text-align: center;
    padding-top: 40%;
  }

  padding-bottom: 3em;

  &.scroll-wrap {
    max-height: calc(100vh - 341px);
  }

  .text-wraper {
    // position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.5em;
  }

  .image-placeholder {
    border: 1px dashed $brand-color;
    height: 15em;
    text-align: center;
    background-color: $sky-blue;
    position: relative;
    border-radius: $md-radius;
    padding-bottom: 3.5em;

    button.delete {
      position: absolute;
      right: 1em;
      top: 1em;
      font-size: 1em;
      color: $red;
    }

    &.img-added {
      border: 1px solid $stroke;
      background-color: #fff;

      &::after {
        content: '';
      }

      &:hover {
        border: 1px dashed $brand-color;
      }
    }

    img {
      max-height: 100%;
      width: auto;
      margin: 0 auto;
    }
  }
}

.bottom-0 {
  bottom: 0;
}

.brand-tx-color {
  ::-webkit-input-placeholder {
    /* Edge */
    color: $brand-color !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $brand-color !important;
  }

  ::placeholder {
    color: $brand-color !important;
  }
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

.img-thumbnail-for-report {
  // width: 40.4em;
  height: 15em;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.error-field-doted {
  border-color: $red;
  box-shadow: 0 0 0 3px rgb(239 4 4 / 25%);

  .css-1s2u09g-control {
    border-color: $red !important;
    // box-shadow: 0 0 0 3px rgb(239 4 4 / 25%) !important;
  }
}

.sample-side-panel-content {
  padding: 0;
  top: 10px;
  // background-color: $brand-color;
}

.side-panel-header-spec-library {
  height: 73px;
  line-height: normal;
}

.custome-pagination-height {
  height: 30px;
}

.sample-side-panel-margin-top {
  top: 10px;
}

.side-panel-header-spec-library-prev {
  height: 60px;
}

.spec-library-title-height {
  border-radius: $md-radius;
}

.spec-library-border-down {
  border-bottom: 1px solid #dadada;
}

.message-dot {
  position: absolute;
  top: 0px;
  left: 50px;
  width: 5px;
  height: 5px;
  background-color: rgb(221, 17, 17);
  border-radius: 50%;
}

.close-comment {
  position: absolute;
  right: 15px;
  top: -35px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $stroke;
  padding: 14px 8px;
  z-index: 1;
  width: 300px;
}

.mleft62 {
  margin-left: 62px;
}

.fade-color {
  color: #f6f6f6;
}

.eval-comment {
  .input1-wrap {
    width: 97%;
  }
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.close-info {
  height: calc(100vh - 370px);
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.close-infodoc {
  height: calc(100vh - 330px);
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.dot-indicator-non-spec {
  width: 10px;
  height: 10px;
  background-color: #d1414b;
  border-radius: 50%;
  top: 0px;
  left: 61px;
  z-index: 2;
  position: absolute;
}

.ml-60 {
  margin-left: 60px;
}

.ml-80 {
  margin-left: 80px;
}

.non-spec-text-end {
  text-align: end !important;
}

.non-spec-text-center {
  text-align: center !important;
}

.modal-para {
  position: absolute;
  top: 220px;
}

.border-dark-down {
  border-bottom: 1px solid #bcbcbc;
}

.stylecard {
  border: 1px solid #ece6e6;
}

.select-width {
  width: 100px;
}

.jodit-container:not(.jodit_inline) .jodit-wysiwyg img {
  width: 301px !important;
}

.termsandconImg:not(.jodit_inline) .jodit-wysiwyg img {
  width: 80px !important;
}

.w-36em {
  width: 36em;
}

.closeSpecLibrary {
  height: 90%;
}

.closeSpec .side-panel__close {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 60px;
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.timeline-text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.width-yard-cost {
  width: 35rem;
}

.DateInput_input__disabled {
  font-style: normal !important;
}

.DateRangePickerInput__withBorder {
  border-radius: 8px;
  padding: 1.5px;
}

.CalendarDay__selected {
  background-color: $brand-color !important;
  border: 1px solid $brand-color !important;
}

.CalendarDay__selected_span {
  border: 1px solid #5f8ed4 !important;
  background-color: #5f8ed4 !important;
}

.reports-repair {
  .scroll-wrap {
    max-height: calc(100vh - 372px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 6px;
    min-height: 1em;
  }

  .scroll-wrap1 {
    max-height: calc(100vh - 280px) !important;
  }
}

.section-mr {
  position: absolute;
  left: 185px;
}

.more-margin {
  margin-left: 160px;
}

.section-isexpand {
  position: absolute;
  left: 260px;
}

.dd-margin {
  margin-left: 107px;
}

.daily-report-template {
  .jodit-container {
    height: calc(100vh - 217px) !important;
  }

  .jodit-workplace {
    background-color: #fff !important;
    border-color: transparent !important;
    max-width: 1300px !important;
    margin: 14px auto;
    padding: 14px;
    border-radius: $md-radius;
    box-shadow: 0 1px 2px rgb(204 210 218 / 7%), 0 2px 4px rgb(204 210 218 / 7%), 0 4px 8px rgb(204 210 218 / 7%), 0 8px 16px rgb(204 210 218 / 7%),
      0 16px 32px rgb(204 210 218 / 7%), 0 32px 64px rgb(204 210 218 / 7%);
  }

  .jodit-container:not(.jodit_inline) {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .jodit-workplace {
    height: calc(100vh - 230px) !important;
  }

  .jodit-toolbar__box {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: none !important;
  }

  .jodit-toolbar-button__button {
    &:hover {
      background-color: #ffffff !important;
    }
  }

  .jodit-status-bar {
    background: transparent;
    border-top: transparent;
    max-width: 1300px !important;
    margin: 0 auto;
  }

  .jodit-workplace+.jodit-status-bar:not(:empty) {
    border-top: 0px;
  }
}

.report-canvas {
  max-width: 1250px !important;
  overflow: auto;
  box-shadow: 0 1px 2px rgb(204 210 218 / 7%), 0 2px 4px rgb(204 210 218 / 7%), 0 4px 8px rgb(204 210 218 / 7%), 0 8px 16px rgb(204 210 218 / 7%),
    0 16px 32px rgb(204 210 218 / 7%), 0 32px 64px rgb(204 210 218 / 7%);
  margin: 0 auto;
  background-color: $white;
  border-radius: $md-radius;
  padding: 14px;
}

.report-ctrl {
  box-shadow: 0 1px 2px rgb(204 210 218 / 7%), 0 2px 4px rgb(204 210 218 / 7%), 0 4px 8px rgb(204 210 218 / 7%), 0 8px 16px rgb(204 210 218 / 7%),
    0 16px 32px rgb(204 210 218 / 7%), 0 32px 64px rgb(204 210 218 / 7%);
  background-color: $white;
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 25px;
}

.spec-comment {
  .h-spec-comment {
    max-height: calc(100vh - 370px) !important;
    min-height: 10em !important;
  }

  .back-drop .modal {
    padding: 0 !important;
  }

  .modal-header {
    padding: 1.5em !important;
    margin-bottom: 0 !important;
  }

  .back-drop .modal .modal-footer {
    margin-top: 0em;
  }

  .rig-20 {}

  .dot-indicator {
    width: 6px;
    height: 6px;
    background-color: #d1414b;
    border-radius: 50%;
    top: 0;
    left: 18px;
    z-index: 2;
    position: absolute;
  }
}

.border-radius-none {
  border-radius: 0px;
}

.exit-page {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 50%;
  margin-top: 155px;
}

.exit-page-symbol {
  width: 75px;
  height: 75px;
}

.exit-page-close {
  margin-left: 328px;
  margin-top: 40px;
}

.dot-indicator-notification-bell {
  width: 10px;
  height: 10px;
  background-color: #d1414b;
  border-radius: 50%;
  // top: 25px;
  // right: 428px;
  z-index: 2;
  position: absolute;
}

.cursor-default {
  cursor: default !important;
}

.margin-left-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0;
}

.w-50P {
  width: 47%;
}

.job-details-table {
  .table {
    .rt-table {
      .rt-tbody {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        padding-right: 6px;
        min-height: 14em;
      }
    }
  }
}

.notification-bell-count {
  font-size: 9px !important;
  color: $white;
  border-radius: 20px;
  padding: 2px 4px;
  margin-bottom: 32px;
  background-color: $red;
  position: absolute;
  left: 23px;
  top: -6px;
  min-width: 20px;
  text-align: center;
}

.top-0 {
  top: 0px !important;
}

.h-300 {
  height: 300px;
}

.paint-cost-select {
  .css-tlfecz-indicatorContainer {
    padding: 6px !important;
  }
}

.variation-comment-section {
  margin-top: 68px;
  margin-left: 20px;
  margin-right: 20px;
}

.yard-dd {

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    height: 34px !important;
    min-height: 34px !important;
  }
}

.header-loader {
  max-width: 55px !important;
}

.link-style {
  display: block;
  word-wrap: break-word;
  max-width: 400px;
}

.disable_footbtn {

  .primary-btn,
  .secondary-btn {

    &:disabled,
    &.disabled {
      display: none;
    }
  }

  .yard-download {

    &:disabled,
    &.disabled {
      display: block;
    }
  }
}

.sticky-footer {
  position: absolute;
  bottom: 5px;
  width: 100%;
  left: 0;
}

.non-sticky-footer {
  width: 100%;
  left: 0;
}

.scroll-wrap-v {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  padding-right: 6px;
  min-height: 14em;
  scrollbar-color: #555969 #e7ecf0;
  scrollbar-width: thin;
}

.upload-sec {
  cursor: pointer;

  .thmb-xlg {
    border: 2px solid $border-gray;
  }

  .link-btn {
    opacity: 0.5;
  }

  &:hover {
    .thmb-xlg {
      border: 2px solid $brand-color;
    }

    .link-btn {
      opacity: 1;
    }
  }
}

.document-dot {
  position: absolute;
  top: 1px;
  right: 10px;
  width: 5px;
  height: 5px;
  background-color: rgb(221, 17, 17);
  border-radius: 50%;
}

.terms-strip {
  .text {
    overflow-y: auto;
    max-height: 262px;
    font-size: 13px;
    margin-bottom: 1.5em;
  }

  .primary-btn {
    background-color: $green;
    min-width: 110px;

    &:hover {
      opacity: 0.8;
    }
  }

  .secondary-btn {
    border: 1px solid $green;
    background-color: $white;
    color: $green;

    &:hover {
      background-color: $green;
      color: $white;
    }
  }
}

.pjt_filter {

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    width: 200px;
  }
}

.react-tooltip {
  max-width: 400px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
  border-radius: 4px;
  z-index: 999 !important;
}

.po-currency {
  .css-26l3qy-menu {
    .css-4ljt47-MenuList {
      max-height: 130px !important;
    }

    .css-11unzgr {
      max-height: 130px !important;
    }
  }
}

.variationDescription {
  .side-panel__content {
    height: calc(100% - 40px) !important;
  }

  .jodit-container {
    height: calc(100vh - 220px) !important;
  }

  .jodit-workplace {
    min-height: 450px !important;
  }
}

.jodit-heightfull {
  .jodit-container .jodit .jodit_theme_default .jodit-wysiwyg_mode {
    min-height: 400px !important;
  }
}

// .jodit-wysiwyg
// {
//   min-height: 400px !important;
// }

.sub-strip {
  border: 1px solid #ddd;
  padding: 4px 10px;
  border-radius: 16px;
  background: #edf0f4;
}

.no-data-color {
  color: #a70101;
}

.yard-jodit {
  .jodit-workplace {
    min-height: calc(100vh - 370px) !important;
  }
}

.border-hover:hover {
  border-color: $brand-color;
}

.permission-table {
  .wrap-table {
    height: calc(100% - 150px);
    overflow-y: scroll;
  }

  .centre-text {
    text-align: center;
  }

  width: 100%;

  .bg-green {
    background-color: $green;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }

  .bg-red {
    background-color: $red;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }

  .heading {
    background-color: $sky-blue;

    td {
      font-weight: 600;
    }
  }

  th {}

  td {
    padding: 5px;
    border: 1px solid #ccc;

    .icon-close,
    .icon-check-rnd {
      font-size: 1.125em;
    }
  }

  .no-border-tr {
    td {
      border: none;
    }
  }

  .bg-sky-white {
    text-align: center;
  }

  .no-border {
    border-right: none;
    border-top: none;
    border-bottom: none;
    border-left: 1px solid #ccc;
  }

  .no-border-last {
    border-top: none;
    border-bottom: 1px solid #ccc;
  }

  .no-border-down {
    border-bottom: none;
  }
}

.variance-marg {
  margin-left: 100px;
}

.vr-mg {
  margin-left: 245px;
}

.scroll-wrap-poha {
  max-height: calc(100vh - 190px);
  // overflow-y: auto;
  padding-right: 6px;
  min-height: 14em;
}

.word-bk {
  .item-renderer {
    word-break: break-all;
  }
}

.no-h {
  .attached-file-multi {
    overflow-y: auto;
    max-height: calc(100vh - 350px);
  }
}

.dropdown-heading-dropdown-arrow {
  cursor: pointer;
}

.search-advanced {
  .icon-close-line {
    left: 245px;
    margin-top: -3px;
  }
}

.search-project {
  .text-box {
    padding: 0 10em 0 1em;
  }

  .icon-close-line {
    top: 2px;
    right: 82px;
  }

  .advance-arrow {
    position: absolute;
    height: 34px;
    right: 46px;
    top: 1px;
    width: 34px;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;

    span {
      font-size: 8px;
      font-weight: 800;
      color: #6e7985;
    }

    &:hover {
      background-color: $brand-color;

      span {
        color: #fff;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.text-italic {
 font-style: italic;
}

.fixed-gnt {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
}

th.fixed-gnt {
  z-index: 110;
}

.z-index-1100 {
  z-index: 110;
}

.z-index-120 {
  z-index: 120;
}

.z-index-999 {
  z-index: 999 !important;
}

.z-index-998 {
  z-index: 998;
}

.z-index--5 {
  z-index: -5;
}

.hide {
  display: none !important;
}

.list-view .project-name {
  font-weight: 500;
  font-size: 15px;
}

.mngmnt-name {
  font-weight: 500;
}

.role-item {
  padding-right: 36px !important;
  position: relative;

  .dir-ico {
    right: 14px;
    top: 14px;
    color: $brand-color;
  }

  &:hover {
    background-color: $sky-white;
  }
}

.pcs-table {
  td {
    padding: 3px;
  }
}

.width-300 {
  width: 300px;
}

.font-127 {
  font-size: 12.7px;
}

@media (max-width: 1100px) {
  .view-txt-respo {
    display: none;
  }
}

.margin-yard-show {
  margin-left: 25px;
}

.text-semi-bold {
  font-weight: 500;
}

.chat-bubble {
  background-color: $sky-white;
  border-radius: 16px;
}

.po-ind {
  border: 1px solid #ddd;
  font-size: 13px;
  width: 50px;
  border-radius: $sm-radius;
  text-align: center;
  background-color: $sky-white;
}

.y-names {
  .col-gap-1 {
    column-gap: 0.5em;
  }

  .item-set {
    font-size: 14px;
  }

  .rnd-btn {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.applied-search {
  display: flex;
  position: absolute;
  top: 4px;
  left: 4px;
  column-gap: 6px;
  height: 2em;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  min-width: 260px;

  .count-badge {
    text-align: center;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: $brand-color;
    color: #fff;
  }
}

.clear-selected-button {
  display: none !important;
}

.currency-scroll-wrap {
  .table-scroll .rt-tbody {
    max-height: calc(100vh - 400px) !important;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.info-details {
  position: absolute;
  background: #eee;
  padding: 10px 16px;
  width: 300px;
  top: 30px;
  left: 10px;
  border-radius: 10px;
}

/* To hide arrow Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.copy-right {
  margin: 4% auto;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.height-fix-yard {
  min-height: calc(100vh - 196px);
  max-height: calc(100vh - 162px);
}

.resource-footer-copy-right {
  min-height: calc(100vh - 245px);
  max-height: calc(100vh - 162px);
}

// /* To hide arrow Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

/* To hide arrow  Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.share-report-btn {
  margin-left: 385px;
}

.report-link a {
  text-decoration: underline;
}

.mail-foot {
  // font-family: calibri, sans-serif;
}

.scroll-wrap-task-admin {
  max-height: calc(100vh - 360px);
  overflow: auto;
  overflow-y: auto;
  padding-right: 6px;

  table {
    border: none !important;
  }

  thead {
    th {
      font-size: 12px;
      font-weight: 600;
      overflow: visible !important;
      text-align: left;
      white-space: normal !important;
      text-transform: uppercase;
      background-color: #fff !important;
      border-bottom: 1px solid #eee !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      color: #212830 !important;
    }
  }

  .responsile {
    width: 100px;
  }

  .text-center {
    text-align: center;
  }
}

.footer-proj-list-margin {
  margin: -9% auto !important;
}

.template-info-details {
  position: absolute;
  background: #fff;
  max-width: 500px;
  border-radius: 10px;
  right: 19px;
  z-index: 99;
  top: 95px;
  border: 1px solid #ccd2da;

  .fixed-h {
    max-height: calc(100vh - 350px);
    overflow: auto;
  }
}

.card-share {
  position: relative;
  top: 30px;
}

.large-line {
  border-top: 1px solid black;
  width: 100%;
  margin-top: 20px;
}

.bottom-line-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  margin-left: 37px;
  // padding: 10px 5px;
  font-size: 13px;
  width: 90%;
  font-weight: 500;
}

.underline {
  text-decoration: underline;
}

.custom-underline {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  width: 90%;
}

.custom-underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.subject-margin {
  margin-top: 10px;
}

.share-btn-daily {
  margin-top: -7px;
  margin-left: 10px;
}

.margin-red {
  border: 1px solid red;
}

.info-supplementary {
  position: absolute;
  background: #eee;
  padding: 10px 16px;
  width: 811px;
  top: 10px;
  left: 10px;
  border-radius: 10px;
}

.create-contact {
  border: 1px solid #ccd2da;
  height: 38px !important;
  border-radius: 6px;
  padding: 0em 6px;
}

.scorll-body-wrap {
  // max-height: calc(100vh - 248px);
  // overflow: auto;
  // overflow-y: auto;
  // min-height: calc(100vh - 289px);

  min-height: calc(100vh - 210px);
  display: flex;
  flex-direction: column;
}

.user-list-policy {
  min-height: calc(100vh - 250px);
  display: flex;
}

.vessel-policy {
  min-height: calc(100vh - 240px);
  display: flex;
  flex-direction: column;
}

.login-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.quote-content-yard-quatation {
  margin-top: 80px !important;
}

.close-phase-footer {
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
}

.dd-history-footer {
  min-height: calc(100vh - 290px);
  display: flex;
  flex-direction: column;
}

.mt-g-0 {
  margin-top: -5em !important;
}

.share-multi-email .react-multi-email {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  outline: none !important;
  margin-left: 37px !important;
  font-size: 13px !important;
  width: 60em !important;
  font-weight: 500 !important;
  border-radius: 0 !important;
  min-height: 0em !important;
  padding: 0px !important;
}

.share-multi .react-multi-email>input {
  width: 200px !important;
}

.exit-page-policy-footer {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
}

.port-height {
  width: 20px;
  height: 20px;
}

.white-space-preserve {
  white-space-collapse: preserve;
}

.footer-tender {
  padding-left: 288px;
  padding-top: 11px;
}

.tender-mr {
  margin-bottom: 80px;
}

.footer-specify {
  padding-top: 11px;
}

.footer-repair {
  padding-left: 288px;
  padding-top: 11px;
}

.modal-Project {
  height: 2.6em;
}

.audit-position {
  position: absolute;
  top: 125px;
}

.vessel-footer {
  position: absolute !important;
  left: 50%;
}

.vessel-img-box {
  width: 46px;
}

.modal-package {
  height: 2.72em !important;
}

.footer-sof {
  padding-left: 7px;
  padding-top: 15px;
}

.footer-jobprogress {
  padding-left: 153px;
  padding-top: 11px;
}

.footer-photoreport {
  padding-left: 333px;
  padding-top: 11px;
}

.modal-package {
  height: 2.72em !important;
}

.pb-s4 {
  padding-bottom: 5em;
}

.brand-logo {
  position: relative;

  &.staging-build::after {
    content: 'STAGING';
    background-color: #ffbb28;
    color: #251a01;
    position: absolute;
    right: -60px;
    top: 3px;
    border-radius: 4px;
    padding: 4px;
    font-size: 11px;
    font-weight: 600;
    animation: blinkasya 1.5s linear infinite;
  }

  &.app-build::after {
    content: 'LIVE';
    background-color: $green;
    color: $grey;
    position: absolute;
    right: -32px;
    top: 4px;
    border-radius: 4px;
    padding: 4px;
    font-size: 11px;
    font-weight: 600;
    animation: blinkasya 1.5s linear infinite;
  }
}

@keyframes blinkasya {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.badge-yardName {
  margin-left: 20px;
}

.ribbon {
  width: 130px;
  height: 150px;
  overflow: hidden;
  position: absolute;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
  }

  &.Highest::before,
  &.Highest::after {
    border: 5px solid #a94216;
  }

  &.Average::before,
  &.Average::after {
    border: 5px solid #16a0a9;
  }

  &.Lowest::before,
  &.Lowest::after {
    border: 5px solid #a98916;
  }

  &.Highest span {
    background-color: #ff5c00;
  }

  &.Average span {
    background-color: #16a0a9;
  }

  &.Lowest span {
    background-color: #ffc700;
  }

  span {
    position: absolute;
    display: block;
    width: 120px;
    padding: 2px 0px 0px 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
  }
}

.ribbon-top-left {
  top: -4px;
  left: -4px;

  span {
    right: 58px;
    top: 18px;
    transform: rotate(-45deg);
  }

  &::before {
    top: -6px;
    right: 72px;
  }

  &::after {
    bottom: 95px;
    left: 0;
  }
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.jodit-wysiwyg {
  hr {
    height: 6px;
    background-color: rgb(0, 175, 239);
    border: none;
    margin: 0px;
  }

  table {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.mb5em {
  margin-bottom: 5em;
}

.status-select {
  select {
    border-radius: 5px;
    border: 1px solid;
  }
}

.ms50p {
  margin-left: 35%;
}

.bottom-mrn {
  margin-bottom: 40px;
}

.overflow-auto {
  overflow: auto;
}

.bg-pending-task {
  background-color: #feeeab;
}

.abc-supply {
  padding: 1em 1.9em !important;
}

.h-v {
  max-height: calc(100vh - 280px) !important;
  min-height: calc(100vh - 280px) !important;
}

.dot-indic {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $orange;
    display: flex;
    right: -10px;
    top: 0;
  }
}

.scroll-wrap-map-spec-admin {
  max-height: calc(100vh - 308px);
  overflow: auto;
  overflow-y: auto;
  padding-right: 6px;
}

.width-24 {
  width: 25em !important;
}

.c-sel {
  margin: 2px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.set-width {
  width: 400px;
}

.set-name-width {
  width: 220px;
}

.scrollbar-speclist-attachments {
  overflow-y: auto;
  max-height: 280px;
}

.searchbar-userlist-width-increase {
  width: 418px;
}

// .draft-icom {
//   position: relative;
//   .draft-icon {
//     position: absolute;
//     top: 0;
//     color: $orange ;
//     display: none;
//   }
// }
.draft-ico {
  border-top: 2px solid orange;
}

.management {
  flex: 0 0 auto;
  width: 20.33333333%;
}

.vessels {
  flex: 0 0 auto;
  width: 12%;
}

.spacing-bfr-mngmnt {
  flex: 0 0 auto;
  width: 26.33333333%;
}

.btn-width {
  width: 12.666667%
}

.dot-indicator-comment {
  display: flex;
  width: 9px;
  height: 9px;
  background-color: $red;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
}
.spec-content {
  display: flex; 
  flex-wrap: nowrap; 
  width: 100%;
  overflow: hidden;
}

.spec-content span {
  white-space: nowrap;
}

.spec-name {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 34px);
}

.d-flex.button-ctrl {
  flex-shrink: 0;
  margin-left: 10px;
}
.spec-content-container {
  max-width: calc(100vw - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-16 {
  font-size: 16px;
}
.gap-3 {
  gap: 3px;
}
.check-green {
  color: #008000;
  &.disabled {
    color: #808080;
  }
}
.close-red {
  color: #a90e0e;
}
.pointer-event-none {
  pointer-events: none;
}
.user-phone-wrap {
  white-space: nowrap;
  align-items: center;
  vertical-align: middle;
  .icon-cell-phone:before {
    font-weight: bold;
    padding-left: 2px;
  }
}
.gap-5 {
  gap: 5px;
}
.date-picker-container-mw {
  min-width: 16em;
}
@media (max-width: 1294px) {
  .date-picker-container-mw {
    min-width: 17em;
  }
}
.repair-date-container {
  width: 100%;
  display: block !important;
  position: relative;
}
.repair-date-tooltip {
  position: absolute;
  top: 25px;
  bottom: 0;
  margin: auto;
  right: 35px;
}
.repair-tooltip-cont {
  margin-left: 6.5rem !important;
  margin-top: -7px !important;
}
.close-custom-supervisor {
  position: absolute;
  top: 31px;
  right: 8px;
}
.relative-position {
  position: relative;
}