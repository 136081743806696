@import '../base/reset';
@import '../base/variables';
@import '../base/mixins';

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #d5d5d5;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background: $content-black;
    border-radius: $lg-radius;
}

::-webkit-scrollbar-thumb:hover {
    background: $head-black;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

label {
    font-weight: 500;
   

    &.required::after {
        content: "*";
        color: $red;
        font-size: 1em;
        margin-left: 0.3em;
    }
}

//---- mixins ---

@mixin button {
    font-size: 13px;
    border-radius: $md-radius;
    border: 0;
    background: $brand-color;
    color: $white;
    padding: 1em;
    font-weight: 500;
    height: $md-height;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &:hover {}
}

@mixin size-addon {
    &.xsmall {
        font-size: 0.9125em;
        height: $sm-height;
        width: 4.5em;
    }
    &.small {
        font-size: 0.8125em;
        height: $sm-height;
    }

    &.large {
        font-size: 0.9125em;
        height: $lg-height;
    }

    &.xlarge {
        font-size: 1em;
        height: $xl-height;
    }
    &.xxsmall {
        font-size: 0.9125em;
        height: $sm-height;
        width: 3em;
    }
}

@mixin formbox {
    border: 1px solid $input-gray;
    height: $md-height;
    border-radius: $md-radius;
    padding: 0em 6px;
}

//---- Buttons ---
//---- primary button ---

.primary-btn {
    @include button();
    @include size-addon();

    &:hover,
    &:active,
    &:focus {
        background-color: $hover-color;
    }

    &:disabled,
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.office-btn {
        background-color: transparent;
        border: 1px solid $input-gray;
        color: $orange;

        &:hover {
            background-color: white;
            border-color: $orange;
        }

    }
}

//---- Secondary button ---

.secondary-btn {
    @include button();
    color: $brand-color ;
    background: $white;
    border: 1px solid $brand-color;

    @include size-addon();

    &.small {
        background: $white;
        color: $brand-color ;
        border: 1px solid $brand-color ;
    }

    &:hover,
    &:active,
    &:focus {
        color: $white ;
        background: $brand-color;
    }

    &:disabled,
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        color: $brand-color ;
        background: $sky-blue;
    }
}

//---- Round Corner button ---
.rnd-btn {
    @include button();
    color: $brand-color ;
    background: $white;
    border: 1px solid $brand-color;
    border-radius: 2rem;
    // min-width: 9em;

    @include size-addon();

    &:hover,
    &:active,
    &:focus {
        background-color: $brand-color;
        color: $white;
        outline: none;
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            color: $brand-color ;
            background: $sky-blue;
        }
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        color: $brand-color ;
        background: $sky-blue;
    }
}

//---- Link button ---
button:focus{
    box-shadow: 0 0 0 3px rgb(34 105 213 / 25%);
}
.icon-btn{
    padding: 6px;
    &:hover, &:focus{
        background-color: $sky-blue;
        border-radius: 50%;
    }
}
.upload-btn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}


.upload-btn input[type=file] {
    width: 130px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.link-btn {
    font-weight: 500;
    color: $brand-color;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        color: $hover-color;
        border: none;
        outline: none;
    }

    &:disabled,
    &.disabled {
        opacity: .3;
        cursor: not-allowed;
    }

}

//---- Line Hight Lighted button ---

.lineBtn-set {
    .link-btn {
        border-bottom: 2px solid $white;
        padding-bottom: 0.8em;
        color: $content-black;

        &.active,
        &:hover,
        &:focus {
            border-bottom-color: $brand-color;
            color: $brand-color;
        }
    }
}

//---- circle icon button ---
.circle-icon-btn {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $sky-white;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    span {
        font-size: 2.5rem;
    }
}
.circle-ico-btn {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: $sky-white;
    color: $content-black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;

    span {
        font-size: 2rem;
    }

    &:hover {
        background-color: $brand-color;
        color: $white;
    }
}
.circle-ico-btn-sm {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $sky-white;
    color: $content-black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;

    span {
        font-size: 1.7rem;
    }

    &:hover {
        background-color: $brand-color;
        color: $white;
    }
}
//---- card ---
.card {
    box-shadow: $shadow-light;
    background-color: $white;
    // border: 1px solid $stroke;
    border-radius: $xl-radius;

    &.collapse {
        overflow: hidden;
        transition: height 200ms;
    }
}
.card-bar {
    box-shadow: $shadow-light;
    background-color: $white;
    border: 1px solid $stroke;
    border-radius: $xl-radius;
    max-height: 500px;

    &.collapse {
        overflow: hidden;
        transition: height 100ms;
    }
}
.card-verticalBar{
    // display: block;
    // box-sizing: border-box;
    height: 276px;
    width: 520px;
}
.card-height {
    height:570px;
    box-shadow: $shadow-light;
    background-color: $white;
    border: 1px solid $stroke;
    border-radius: $xl-radius;

    &.collapse {
        overflow: hidden;
        transition: height 200ms;
    }
}
.card-height-task{
    height:570px;
    box-shadow: $shadow-light;
    background-color: $white;
    border: 1px solid $stroke;
    border-radius: $xl-radius;

    &.collapse {
        overflow: hidden;
        transition: height 200ms;
    }
}

.card-inverse {
    background-color: $head-black;
    border-radius: $lg-radius;
}

.line-card{
    border: 1px solid $stroke;
    border-radius: $lg-radius;
}

//---- Form Elements ---

//---- Normal Text Box ---
.text-box {

    @include formbox();
    @include size-addon();


    &:focus {
        border-color: $brand-color;
        box-shadow: 0 0 0 3px rgb(34 105 213 / 25%);
        outline: none;
    }
  
}

.error-field{

    border-color: $red !important;
    box-shadow: 0 0 0 3px rgb(239 4 4 / 25%);
    .css-1s2u09g-control{

        border-color: $red !important;

        // box-shadow: 0 0 0 3px rgb(239 4 4 / 25%) !important;

    }

}
.error-fields{
    border-color: $red !important;
    box-shadow: 0 0 0 3px rgb(239 4 4 / 25%) !important;
    opacity: 1 !important;
}

//---- Normal Text Box ---
.search {
    position: relative;
    min-width: 22em;

    &:hover {
        .text-box {
            border-color: $brand-color;
        }
    }

    .icon-search {
        position: absolute;
        font-size: 1.2em;
        top: 0px;
        right: 0px;
        color: $menu-color;
        cursor: pointer;
        border-radius: 0px 6px 6px 0px;
        width: 34px;
        height: 32px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $brand-color;
            color: $white;
        }
    }

    .close-icon{
        position: absolute;
        // font-size: 5px;
        top: -5px;
        right: 40px;
        color: $menu-color;
        cursor: pointer;
        border-radius: 0px 0px 0px 0px;
        width: 40px;
        height: 32px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      
        &:hover {
            background-color: $brand-color;
            color: $white; 
            padding-right: 30px;
            padding-left: 10px;
            margin: 7px 0px 0px 0px;
           
        }
      }
    

    


    &.large {
        .icon-search {
            top: 2px;
            right: 3px;
            border-radius: 6px;
            font-size: 1.4em;
        }
    }

    .text-box {
        padding: 0 3em 0 1em;
        width: 100%;
        &::placeholder{
            font-size: 12px;
            font-weight:500;
        }
    }
}
.search-large {
    min-width: 43em;
}

//---- Select ---

.select {
    @include formbox();
    @include size-addon();
 
    &.small {      
        padding: 0 4px;
        text-align: center;
    }
}


.check-box {
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }
    &.disabled {
        label {
            cursor: not-allowed;
            &::before {
                background-color: $stroke;
            }
            &:hover {
                &::before {
                    box-shadow: none;
                    border-color: $border-gray;
                    cursor: not-allowed;
                }
            }
        }
    }
    &.viewmode {
        label {
            cursor: not-allowed;
            &:hover {
                &::before {
                    cursor: not-allowed;
                }
            }
        }
    }
    label {
        position: relative;
        cursor: pointer;
        font-weight: 500;
        
        &:hover{
            &::before{
                border: 1px solid $brand-color;
                box-shadow: 0 0 0 3px rgb(34 105 213 / 25%);
            }
            
        }

        &::before {
            content:'';
            -webkit-appearance: none;
            background-color: #fff;
            border: 1px solid $border-gray;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 6px;
            border-radius: $md-radius;
            width: 20px;
            height: 20px;

            
        }
    }

    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 7px;
        width: 6px;
        height: 14px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      input:checked + label::before {
        background-color: $brand-color;
        border-color: $brand-color;
      }
}

//---- Modal ---

.back-drop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(8px);
    background-color: rgb(0 0 0 / 30%);
    display: flex;
    align-items: center;

    .comment {
        box-shadow: 0px 19px 20px 0px rgb(0 0 0 / 20%);
        background-color: $white;
        border: 1px solid $stroke;
        border-radius: $md-radius;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        
        @include respond(w1200){
            &.medium{
                min-width: 600px;
            } 
        }

        &.small {
            width: 330px;
        }

        &.medium {
            width: 50%;
           
        }

        &.large {
            width: 90%;
        }

        &.full {
            width: 100%;
        }
        &.sub-large {
            width: 80%;
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5em;

            h3 {
                font-size: 1.2em;
                color: $head-black;
                font-weight: 500;
            }
        }

        &.modal-scroll {
            .modal-body {
                max-height: calc(100vh - 250px);
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        .modal-body {
            width: 100%;
           height: 340px;

        }

        .modal-footer {
            margin-top: 1.5em;

        }
    }

    .modal {
        box-shadow: 0px 19px 20px 0px rgb(0 0 0 / 20%);
        background-color: $white;
        border: 1px solid $stroke;
        border-radius: $md-radius;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        margin: 0 auto;


        &.small {
            width: 330px;
        }

        &.medium {
            width: 50%;
            max-width: 710px;
        }

        &.xl {
            width: 60%;
        }
        &.large {
            width: 90%;
        }

        &.full {
            width: 100%;
        }
        &.sub-large {
            width: 80%;
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5em;

            h3 {
                font-size: 1.2em;
                color: $head-black;
                font-weight: 500;
            }
        }

        &.modal-scroll {
            .modal-body {
                max-height: calc(100vh - 250px);
                overflow-y: auto;
                overflow-x: hidden;
            }
            
.modal-empty-spec{
    max-height: calc(100vh - 320px) ;
    overflow-y: auto;
    overflow-x: hidden;
  
  } 
           
        }
       

        .modal-body {
            width: 100%;
            min-height: auto;

        }

        .modal-footer {
            margin-top: 1.5em;

        }
    }
    .chat {
        box-shadow: 0px 19px 20px 0px rgb(0 0 0 / 20%);
        background-color: $white;
        border: 1px solid $stroke;
        border-radius: $md-radius;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        margin-left: 90px;
        margin-top: 150px;



        &.medium {
            width: 490px;
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5em;

            h3 {
                font-size: 1.2em;
                color: $head-black;
                font-weight: 500;
            }
        }

        &.modal-scroll {
            .modal-body {
                max-height: calc(100vh - 250px);
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        .modal-body {
            width: 100%;
            height: 200px;
        }
        .modal-footer {
            margin-top: 1.5em;

        }

    }
}

//---- comment ---


/* START TOOLTIP STYLES */


/**********************/
.DateInput_input {
    @include formbox();
    @include size-addon();
}

/**********************/
.toggle-switch {
    display: flex;

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: 4em;
        width: 3em;
        height: 1.5em;
        background: $sky-blue;
        display: block;
        border-radius: 100px;
        position: relative;
        white-space: nowrap;

        &:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 3px;
            width: 1.2em;
            height: 1.2em;
            background: $white;
            border-radius: 90px;
            transition: 0.3s;
        }
    }

    input:checked+label {
        background: $brand-color;
    }

    input:checked+label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }

}

/****************Side Pannel ************************/

.side-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.6s;
    transition: visibility 0s 0.6s;
    z-index: 999;

    &__header,
    &__container,
    &__footer {
        position: fixed;
    }

    &__footer {
        bottom: -50px;
        right: 0;
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 10px;
        border-top: 1px solid $stroke;
    }

    &.small {

        .side-panel__header,
        .side-panel__container,
        .side-panel__footer {
            width: 30em;

            @include respond(w768) {
                width: 70%;
            }
        }

    }

    &.medium {

        .side-panel__header,
        .side-panel__container,
        .side-panel__footer {
            width: 50%;
            @include respond(w1300){
                width: 60%;
            }
            @include respond(w1100){
                width: 80%;
            }
            @include respond(w992){
                width: 100%;
            }
        }
        
    }

    &.large {

        .side-panel__header,
        .side-panel__container,
        .side-panel__footer {
            width: 70em;
            @include respond(w1100){
                width: 100%;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        cursor: pointer;
        -webkit-transition: background 0.3s 0.3s;
        transition: background 0.3s 0.3s;
    }

    &__header {

        height: 50px;
        line-height: 50px;
        background: $white;
        z-index: 2;
        box-shadow: $shadow-light;
        -webkit-transition: -webkit-transform 0.3s 0s;
        transition: -webkit-transform 0.3s 0s;
        transition: transform 0.3s 0s;
        transition: transform 0.3s 0s, -webkit-transform 0.3s 0s;
        -webkit-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
        border-bottom: 1px solid $stroke;


        h1 {
            padding-left: 1.4em;
            color: $head-black;
            font-size: 1.2em;
            font-weight: 600;
        }
    }

    &__footer {

        height: 50px;
        line-height: 50px;
        background: $white;
        z-index: 2;
        box-shadow: $shadow-light;
        -webkit-transition: -webkit-transform 0.3s 0s;
        transition: -webkit-transform 0.3s 0s;
        transition: transform 0.3s 0s;
        transition: transform 0.3s 0s, -webkit-transform 0.3s 0s;
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 60px;
        /* image replacement */
        display: inline-block;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 22px;
            left: 20px;
            height: 2px;
            width: 18px;
            background-color: $head-black;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        &::before {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);

        }

        &::after {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &:hover {
            background-color: $brand-color;

            &::before {
                -webkit-transform: rotate(220deg);
                -ms-transform: rotate(220deg);
                transform: rotate(220deg);
            }

            &::after {
                -webkit-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &::before,
            &::after {
                background-color: $white;
                -webkit-transition: -webkit-transform 0.3s;
                transition: -webkit-transform 0.3s;
                transition: transform 0.3s;
                transition: transform 0.3s, -webkit-transform 0.3s;
            }
        }
    }

    &__container {
        height: 100%;
        top: 0;
        background: $body-gray;
        z-index: 1;
        -webkit-transition: -webkit-transform 0.3s 0.3s;
        transition: -webkit-transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
    }

    &__content {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        height: calc(100% - 100px);
        padding: 1.4em;
        overflow: auto;
        /* smooth scrolling on touch devices */
        -webkit-overflow-scrolling: touch;
    }

    &.side-panel--is-visible {
        visibility: visible;
        -webkit-transition: visibility 0s 0s;
        transition: visibility 0s 0s;

        &::after {
            background: rgba(0, 0, 0, 0.6);
            -webkit-transition: background 0.3s 0s;
            transition: background 0.3s 0s;
        }
    }
}

.side-panel--from-right .side-panel__header {
    right: 0;
}

.side-panel--from-left .side-panel__header {
    left: 0;
}

.side-panel--is-visible {
    .side-panel__header {
        -webkit-transition: -webkit-transform 0.3s 0.3s;
        transition: -webkit-transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
    }

    .side-panel__footer {
        -webkit-transition: -webkit-transform 0.3s 0.3s;
        transition: -webkit-transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
        -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
    }

    .side-panel {
        &__close {
            &::before {
                -webkit-animation: side-close-1 0.6s 0.3s;
                animation: side-close-1 0.6s 0.3s;
            }

            &::after {
                -webkit-animation: side-close-2 0.6s 0.3s;
                animation: side-close-2 0.6s 0.3s;
            }
        }

    }
}

.side-panel--from-right .side-panel__container {
    right: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.side-panel--from-left .side-panel__container {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.side-panel--is-visible .side-panel__container {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}


@-webkit-keyframes side-close-1 {

    0%,
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@keyframes side-close-1 {

    0%,
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@-webkit-keyframes side-close-2 {

    0%,
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

@keyframes side-close-2 {

    0%,
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

/***** Pagination ***********/
.pagination {
    .disabled {
        a{
            cursor: auto;
            &:hover {
                border-color: transparent !important;
                color: transparent !important;
            }
        }
    }
}
.pagination {
    li {
        a {
            width: 30px;
            height: 30px;
            display: flex;
            margin: 4px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            cursor: pointer;
            border: 2px solid transparent;
            font-size: 13px;
            color: $content-black;

            &:hover {
                border-color: $brand-color;
                color: $brand-color;
            }
        }

        &.selected a {
            background-color: $brand-color;
            color: $white;
        }
    }
}

/*********************************/

.radio-btn {
    position: relative;

    input[type=radio] {
        position: absolute;
        visibility: hidden;
    }

    label {
        display: block;
        position: relative;
        font-weight: 300;
        font-size: 1.35em;
        padding: 25px 25px 25px 80px;
        margin: 10px auto;
        height: 30px;
        z-index: 9;
        cursor: pointer;
        -webkit-transition: all 0.25s linear;
    }

    .check {
        display: block;
        position: absolute;
        border: 5px solid #AAAAAA;
        border-radius: 100%;
        height: 25px;
        width: 25px;
        top: 30px;
        left: 20px;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
    }

    &:hover {
        label {
            color: #FFFFFF;
        }

        .check {
            border: 5px solid #FFFFFF;
        }

    }
}




.radio-btn:hover .radio-btn .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked~.check {
    border: 5px solid $brand-color
}

input[type=radio]:checked~.check::before {
    background: $brand-color
}

input[type=radio]:checked~label {
    color: $brand-color
}

/*******************************************/

.loading {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin-bottom: 1em;
        font-weight: 500;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        width: .8em;
        height: .8em;
        margin: .5em;
        background: #007DB6;
        border-radius: .8em;
        animation: loading 1s infinite alternate;

        &:nth-of-type(2) {
            background: #008FB2;
            animation-delay: 0.2s;
        }

        &:nth-of-type(3) {
            background: #009B9E;
            animation-delay: 0.4s;
        }

        &:nth-of-type(4) {
            background: #00A77D;
            animation-delay: 0.6s;
        }

        &:nth-of-type(5) {
            background: #00B247;
            animation-delay: 0.8s;
        }

        &:nth-of-type(6) {
            background: #5AB027;
            animation-delay: 1.0s;
        }

        &:nth-of-type(7) {
            background: #A0B61E;
            animation-delay: 1.2s;
        }

    }
}

@keyframes loading {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.pointer {
    cursor: pointer;
}

.progressbar {
    counter-reset: step;

    li {
        float: left;
        width: 155px;
        position: relative;
        text-align: center;
        font-size: 13px;
        cursor: pointer;
        padding: 0 1em;

        &:hover::before {
            box-shadow: 0 0 0 3px rgb(34 213 39 / 25%);
        }

        &::before {
            content: "";
            width: 18px;
            height: 18px;
            border: 2px solid $border-gray;
            display: block;
            margin: 0 auto 5px auto;
            border-radius: 50%;
            line-height: 22px;
            background: $white;
            text-align: center;
            font-weight: bold;
            outline: 4px solid $white;

        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background: $border-gray;
            top: 10px;
            left: -50%;
            z-index: -1;

        }

        &:first-child::after {
            content: none;
        }

        &.is-active {
            color: $brand-color;
            &::after {
                background: $brand-color;
            }

            &::before {
                border-color: $brand-color;
            }
        }


        &.is-done {
            &::after {
                background: $green;
            }

            &::before {
                background: $green;
                content: "✓";
                border: none;
                color: $white;
            }
        }

    }
}

.ribbon-text-box {
    position: relative;

    .label {
        background-color: $sky-white;
        font-size: 0.9125em;
        height: 2.7em;
        display: flex;
        position: absolute;
        top: 6px;
        left: 1px;
        width: 6em;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        align-items: center;
        justify-content: center;
    }

    .text-box {
        padding-left: 6.3em;
    }
}

.side-pannel-modal {
    .back-drop {
        position: absolute;
        background-color: rgb(255 255 255 / 30%);
    }
}

.truncate {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.chip {
    border: 1px solid $stroke;
    border-radius: 50px;
    padding: 2px 10px;
    background: $white;
    font-size: 13px;

}

.no-shadow{
    box-shadow: none;
}

.widget-card{
    height: 570px;
    box-shadow: 0 1px 2px rgb(204 210 218 / 7%), 0 2px 4px rgb(204 210 218 / 7%), 0 4px 8px rgb(204 210 218 / 7%), 0 8px 16px rgb(204 210 218 / 7%), 0 16px 32px rgb(204 210 218 / 7%), 0 32px 64px rgb(204 210 218 / 7%);
    background-color: $white;
    border-radius: 6px;
    margin: .8em;

    .head{
        padding: 14px 20px;
        border-bottom: 1px solid $border-gray;
        font-weight: 500;
    }
    .body{
        padding: 14px 20px;

        &.survey-calendar{
            padding: 0px 7px; 
        }
    }
}
